import { Controller } from "@hotwired/stimulus"
import Stimulus from "../../common/utils/stimulus"
import { Confetti } from "../../common/utils/confetti.min"
import Cookies from "js-cookie"

class Launch extends Controller {
  static targets = [`button`]

  connect() {
    if (Cookies.get(`launch`) !== `true`) {
      this.element.classList.add(`launch--visible`)

      // Pass in the id of an element
      const confetti = new Confetti(`launch`)

      // Edit given parameters
      confetti.setCount(75)
      confetti.setSize(1)
      confetti.setPower(25)
      confetti.setFade(false)
      confetti.destroyTarget(true)
    }
  }

  hideBanner() {
    Cookies.set(`launch`, true, { expires: 365 })
    this.element.classList.remove(`launch--visible`)
  }
}

Stimulus.register(`launch`, Launch)
